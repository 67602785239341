
function leaflet_setup(L){
    L.drawLocal.draw.toolbar.buttons.polygon = 'Малювати поля'
    L.drawLocal.draw.handlers.polygon.tooltip.start = 'Тицьніть, що поставити першу точку'
    L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Тицьніть, що поставити наступну точку'
    L.drawLocal.draw.handlers.polygon.tooltip.end = 'Тицьніть першу точку що закінчити це поле'
    L.drawLocal.draw.toolbar.actions.title = 'Скасувати малювання'
    L.drawLocal.draw.toolbar.actions.text = 'Скасувати'
    L.drawLocal.draw.toolbar.finish.title = 'Збереги поля'
    L.drawLocal.draw.toolbar.finish.text = 'Готово'
    L.drawLocal.draw.toolbar.undo.title = 'Видалити попередню точку'
    L.drawLocal.draw.toolbar.undo.text = 'Назад'
    L.drawLocal.edit.toolbar.actions.save.title = 'Збереги поля'
    L.drawLocal.edit.toolbar.actions.save.text = 'Готово'
    L.drawLocal.edit.toolbar.actions.cancel.title = 'Скасувати редагування'
    L.drawLocal.edit.toolbar.actions.cancel.text = 'Скасувати'
    L.drawLocal.edit.toolbar.buttons.edit = 'Редагувати поля'
    L.drawLocal.edit.toolbar.actions.editDisabled = 'Не знайдено полів для редагування'
    L.drawLocal.edit.toolbar.actions.remove = 'Видалити поля'
    L.drawLocal.edit.toolbar.actions.removeDisabled = 'Не знайдено полів для видалення'
    L.drawLocal.edit.handlers.edit.tooltip.text = 'Потягніть за точку щоб редагувати поле'
    L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Натисніть "Скасувати" щоб скинути радагування'
    L.drawLocal.edit.handlers.remove.tooltip.text = 'Натисніть на поле, щоб видалити його'

    L.EditToolbar.Delete.include({
        removeAllLayers: false
      });
}

export default leaflet_setup;