import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../tools/axiosApi';
import * as turf from '@turf/turf';


function SvgItem({ paths, color }) {
  const [d_path, setD_path] = useState();

  useEffect(() => {
    if (!paths) return;
    let d = ""

    for (let index = 0; index < paths.length; index++) {
      const e = paths[index];
      if (index == 0) {
        d += `M ${e[0] * 90 + 5} ${e[1] * 90 + 5} `
      } else {
        d += `L ${e[0] * 90 + 5} ${e[1] * 90 + 5} `
      }
    }
    d += 'Z'
    setD_path(d);
  }, [paths])

  return (
    <svg width="100%" height="100%" viewBox="0 0 100 100">
      <path d={d_path} stroke='#ffffff' strokeWidth="2" fill='#ffffff20' />
    </svg>
  )
}

function convert_geo(raw_point) {
  let point = turf.point([raw_point.lng, raw_point.lat])
  const projectedPoint = turf.toMercator(point); // You can use other projections like Albers, etc.
  const x_meters = projectedPoint.geometry.coordinates[0];
  const y_meters = projectedPoint.geometry.coordinates[1];
  return { lng: x_meters, lat: y_meters }
}

function FieldItem({ data, field_select }) {
  const [shape, setShape] = useState();
  const [svg_item, setSVG] = useState();


  function f_select() {
    field_select(data.id);
  }

  useEffect(() => {
    if (!data) return;
    let geo = data.geometry;
    if (!geo) return;
    if (geo.length < 1) return;
    let sp = convert_geo(geo[0]);
    let lat_min = sp.lat;
    let lng_min = sp.lng;
    let lat_max = sp.lat;
    let lng_max = sp.lng;

    for (let index = 0; index < geo.length; index++) {
      const element = geo[index];
      let cp = convert_geo(element);
      if (cp.lat > lat_max) lat_max = cp.lat;
      if (cp.lng > lng_max) lng_max = cp.lng;
      if (cp.lat < lat_min) lat_min = cp.lat;
      if (cp.lng < lng_min) lng_min = cp.lng;
    }
    let new_shape = [];
    let ratio = Math.abs(lat_max - lat_min) / Math.abs(lng_max - lng_min)
    let lat_r = ratio;
    let lng_r = 1;
    if (ratio > 1) {
      lng_r = 1 / ratio;
      lat_r = 1;
    }
    for (let index = 0; index < geo.length; index++) {
      const element = geo[index];
      let cp = convert_geo(element);
      let e_lat = cp.lat - lat_max;
      let e_lng = cp.lng - lng_min;
      let sy = e_lat != 0 ? Math.abs(e_lat / (lat_max - lat_min)) * lat_r : 0;
      let sx = e_lng != 0 ? Math.abs(e_lng / (lng_max - lng_min)) * lng_r : 0;
      sy = sy + (1 - lat_r) / 2;
      sx = sx + (1 - lng_r) / 2;

      new_shape.push([sx, sy])
    }
    setShape(new_shape);
  }, [data])


  return (
    <button className='field' onClick={f_select}>
      <div className='shape' >
        <SvgItem paths={shape} color={data.color} />
      </div>
      <div className='text'>
        <div className='header' >
          <p>{data.name}</p>
        </div>
        <p>Власник: {data.owner || '...'}</p>
        <p>Площа: {data.manual_area || data.area} га</p>
      </div>

    </button>
  )
}

function CultureItem_Field({ field, field_click }) {


  function on_f_click() {
    field_click(field.id);
  }

  return (
    <button className='lci_field field' onClick={on_f_click}>{field.name} | {(field.manual_area || field.area).toFixed(2)} га</button>
  )
}

function CultureItem({ color, culture, area, fields, field_click, hide_controls }) {
  const [fields_items, setFields] = useState([]);
  useEffect(() => {
    let fl = [];
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      fl.push(<CultureItem_Field key={index} field={element} field_click={field_click} />)
    }
    setFields(fl);
  }, [fields]);

  return (
    <div className='list_culture_item'>
      <div className='list_culture_item_head'>
        <div className='lci_color' style={{ backgroundColor: color }}></div>
        <p>{culture} | {area.toFixed(2)} га</p>
      </div>
      <div className={`list_culture_item_cont${hide_controls?' hide':''}`}>
        {fields_items}
      </div>
    </div>
  )
}


function ListViewControl({ active_year, set_active_year, year_list, setIsField, is_field }) {
  const [show_dropdown, setShow_dropdown] = useState(false);
  const [yl_items, setYL_items] = useState([]);


  useEffect(() => {
    localStorage.setItem('is_field', JSON.stringify(is_field));
  }, [is_field])

  function year_click(e) {
    set_active_year(e.target.value);
    setShow_dropdown(false);
  }

  useEffect(() => {
    if (!(year_list?.length > 0)) {
      setYL_items([]);
      return
    };
    let yl = []
    for (let index = 0; index < year_list.length; index++) {
      const element = year_list[index];
      yl.push(<button key={element} value={element} onClick={year_click}>{element}</button>)
    }
    setYL_items(yl);
  }, [year_list])

  return (
    <div className='list_view_control_container'>


      {!is_field ?
        <div className="dropdown">
          <div className='year_btn_cont'>
            <p className='year'>Рік:</p>
            <button onClick={() => setShow_dropdown((sd) => !sd)} className="dropbtn list_view_btn"><p>{active_year}</p>
              {show_dropdown ?
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>}
            </button>
          </div>
          <div className={`dropdown-content${show_dropdown ? ' show' : ''}`}>
            {yl_items}
          </div>
        </div> : null}
      <div className='list_view_control'>
        <button onClick={() => setIsField(true)} className={`list_field_btn list_view_btn${is_field ? ' active' : ''}`}>Поля</button>
        <button onClick={() => setIsField(false)} className={`list_spec_btn list_view_btn${!is_field ? ' active' : ''}`}>Культури</button>
      </div>
    </div>
  )
}

export default function FieldList({ is_field, setIsField, fields, field_select, f_open, set_f_open, sum_fields, hide_controls, list_image_ref, area_sum_ref, active_year, set_active_year, year_list, culture_list, change_id }) {
  let fl_obj = useRef();
  const [fields_items, setFields_items] = useState([]);
  const [db_data, set_db_data] = useState([]);

  useEffect(()=>{
    axiosInstance.get('api/fields')
        .then((rp) => {
          set_db_data(rp.data);
        })
        .catch((e) => console.log(e));
  }, [fields, active_year, culture_list, change_id, is_field])

  useEffect(() => {
    if (!db_data) return;
    if (is_field) {
          let fe = [];
          for (let index = 0; index < db_data.length; index++) {
            const element = db_data[index];
            fe.push(<FieldItem key={index} data={element} field_select={field_select} />)
          }
          setFields_items(fe);
    } else {
      let culture_dict = {}
      for (let index = 0; index < db_data.length; index++) {
        const element = db_data[index];
        if (element.cultures?.[active_year]) {
          let key = element.cultures[active_year]
          if (culture_dict[key]) {
            culture_dict[key]['fields'].push(element);
            culture_dict[key]['area'] += parseFloat(element.manual_area || element.area);
          } else {
            culture_dict[key] = {};
            culture_dict[key]['fields'] = [element];
            culture_dict[key]['area'] = parseFloat(element.manual_area || element.area);
            for (let j = 0; j < culture_list.length; j++) {
              const cul = culture_list[j];
              if (cul[0] == key) {
                culture_dict[key]['color'] = cul[1];
              }
            }

          }
        } else {
          let key = 'Не вказано'
          if (culture_dict[key]) {
            culture_dict[key]['fields'].push(element);
            culture_dict[key]['area'] += parseFloat(element.manual_area || element.area);
          } else {
            culture_dict[key] = {};
            culture_dict[key]['fields'] = [element];
            culture_dict[key]['area'] = parseFloat(element.manual_area || element.area);
            culture_dict[key]['color'] = '#ffffff';
          }
        }
      }
      let ci = [];
      var keys = Object.keys(culture_dict).sort().filter(key_i=>key_i!=='Не вказано')
      for (let c = 0; c < keys.length; c++) {
        let cult_key = keys[c];
        let cult_data = culture_dict[keys[c]];
        ci.push(<CultureItem hide_controls={hide_controls} key={c} culture={cult_key} area={cult_data.area} color={cult_data.color} fields={cult_data.fields} field_click={field_select} />)
      }
      let cult_data = culture_dict['Не вказано'];
      if (cult_data){
        ci.push(<CultureItem hide_controls={hide_controls} key={100} culture={'Не вказано'} area={cult_data.area} color={cult_data.color} fields={cult_data.fields} field_click={field_select} />)
      }
      setFields_items(ci);
    }
  }, [db_data, hide_controls])


  return (
    <div ref={fl_obj} className={`field_list${f_open ? ' open' : ''}${hide_controls ? ' hide_controls' : ''}`}>
      <div className='container'>
        <button onClick={() => set_f_open(!f_open)} className='open_close_button' title='Список полів'>
          {f_open ?
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="#ffffff" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="#ffffff" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5" />
            </svg>}
        </button>
        <div className='list' ref={list_image_ref}>
          {fields_items ? fields_items : null}
        </div>
        {year_list.length ? <ListViewControl is_field={is_field} setIsField={setIsField} active_year={active_year} year_list={year_list} set_active_year={set_active_year} /> : null}
      </div>
    </div>
  );
};

