import React, { useEffect, useState } from "react";
import FieldMap from "./components/FieldMap";
import LoginForm from "./components/LoginForm";
// import FieldForm from './components/FieldForm';
// import FieldList from './components/FieldList';

function App() {
  const [show_login, setShow_login] = useState(false);
  const [logout_confirm, setLogoutConfirm] = useState(false);

  function handleLoginRequest(e) {
    setShow_login(true);
  }

  useEffect(() => {
    if (localStorage.getItem("access_token")) setShow_login(false);
    document.addEventListener("onLoginRequest", handleLoginRequest);
    return () =>
      document.removeEventListener("onLoginRequest", handleLoginRequest);
  });

  const handleFieldDrawn = (coords) => {
    // Handle field drawn event (e.g., update state with coordinates)
  };
  function logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/";
    window.location.reload(false);
  }

  return (
    <div className="App">
      <FieldMap onFieldDrawn={handleFieldDrawn} logout={setLogoutConfirm}/>
      {show_login ? (
        <LoginForm close_login_form={setShow_login}/>
      ) : null}
      {logout_confirm ? (
        <div id="login_form">
          <div className="login_island logout_confirm">
            <h3>Ви точно хочете вийти?</h3>
            <div className="confirm_buttons">
              <button onClick={() => setLogoutConfirm(false)}>Ні</button>
              <button className="confirm" onClick={logout}>
                Так
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
