import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../tools/axiosApi';

export default function FieldAreConflict({ data, set_data }) {
  const [is_loading, set_is_loading] = useState(false);


  function submit(e) {
    e.preventDefault()
    set_is_loading(true);
    axiosInstance.patch('api/fields', {'manual_area':null, 'id': data.id})
      .then((response) => {
        set_data(response.data);
        set_is_loading(false);
      }).catch((error) => {
        set_is_loading(false);
      })

  }

  return (
    <div id='login_form'>
      <div className='login_island'>
          <div className='area_conflict'>
            <h5 style={{marginBottom: 10}}>Розрахована нова площа відрізняється від введеної вручну!</h5>
            <p><i>Поле:</i> {data.name}</p>
            {data.owner?<p><i>Власник:</i> {data.owner}</p>:null}
            <p><i>Розрахована площа:</i> {data.area} га</p>
            <p><i>Введена в ручну площа:</i> {data.manual_area} га</p>
            <h5 style={{marginTop: 10}}>Замінити введену в ручну площу на нову розраховану?</h5>
            <div className='conflict_area_buttons'>
              <button onClick={()=>set_data(data)}>НІ</button>
              <button className='submit' onClick={submit}>ТАК</button>
            </div>
          </div>
        {is_loading ?
          <div className='login_error' />
          : null}
      </div>
    </div>
  )
}