import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, LayersControl, fillColor, Circle, FeatureGroup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from "react-leaflet-draw";
import satellite_map_image from '../images/satellite.jpg';
import schematic_map_image from '../images/schematic.jpg';
import L, { latLng } from 'leaflet';
import * as turf from '@turf/turf';
import domtoimage from 'dom-to-image-more';
import axiosInstance from '../tools/axiosApi.js';
import FieldForm from './FieldForm.jsx';
import FieldList from './FieldList.jsx';
import FieldAreConflict from './FieldAreConflict.jsx';
import GenerateUi from './GenerateUi.jsx';
import SettingsUi from './Settings.jsx';


import leaflet_setup from '../tools/leaflet_setup.js';

leaflet_setup(L);





const MapEvents = forwardRef((props, ref) => {
  const map_m = useMap();
  const map = useMapEvents({
    moveend: () => {
      localStorage.setItem('zoom', map.getZoom());
      localStorage.setItem('x', map.getCenter().lat);
      localStorage.setItem('y', map.getCenter().lng);
    },
  })

  useImperativeHandle(ref, () => ({
    get_bounds() {
      console.log(map.getBounds());
    },
    get_start_bounds() {
      map.setZoom(18, { animate: false });
      return map.getBounds();
    },
    set_start_zoom() {
      map.setZoom(13, { animate: false });
    },
    get_origin_bounds() {
      return map.getBounds();
    },
    reset_to_origin(bounds) {
      map.fitBounds(bounds);
    },
    pan_to(coords) {
      map.panTo(coords, { animate: false });
    }
  }));


  return null
});

function calculate_area(layer) {
  var areaInSquareMeters = turf.area(layer.toGeoJSON());
  var areaInHectares = areaInSquareMeters / 10000;
  var area_v = areaInHectares.toFixed(2);
  return area_v
}

function FieldText({ position, data_key, name, area, owner, mtd, is_vert }) {
  let d_key = useRef(data_key);
  const [text, setText] = useState('');

  useEffect(() => {
    let t = '<div><p>'
    t += mtd[0] ? name : ''
    t += mtd[0] & mtd[1] ? ' | ' : ''
    t += mtd[1] ? `${area} га` : ''
    t += mtd[2] ? `</p><p><i>${owner ? owner : ''}</i>` : ''
    t += '</p></div>'
    setText(t);
  }, [name, area, owner, mtd])
  return (
    <Marker key={data_key} position={position} icon={L.divIcon({ html: text, className: `marker_text ${is_vert ? 'vert' : ''}` })} />
  )
}
function MyPol({ data_key, set_ref, geometry, color }) {
  let d_key = useRef(data_key);
  let ref = useRef();
  useEffect(() => {
    if (!ref?.current) return;
    set_ref([d_key.current, ref.current]);
  }, [ref]);

  return (
    <Polygon ref={ref} pathOptions={{ color: color }} positions={geometry} ></Polygon>
  )
}
function Component({ set_fields, block_field_click, put_data, set_area_conflict, is_field, culture_list, active_year }) {
  let text = L.divIcon({ html: '<div><p>Hello</p></div>', className: 'marker_text' });
  const colors = ['#FF0000', '#00FFFF', '#0000FF', '#800080', '#FFFF00', '#00FF00', '#FF00FF', '#FFA500', '#7FFFD4']
  let click_lock = useRef(false);
  let click_lock_timer = useRef();
  let [pols_set, set_pols_set] = useState([]);
  let [db_data, set_db_data] = useState([]);
  let rectangleRef = useRef();
  let is_edit_fix = useRef(false);

  function edit_fix() {
    if (is_edit_fix.current) {
      return
    };
    if (pols_set.length < 1) return;
    setTimeout(() => {
      rectangleRef.current?._toolbars.edit._modes?.edit?.handler.enable();
      setTimeout(() => {
        if (rectangleRef.current?._toolbars.edit._actionButtons?.[1]?.button | !is_edit_fix.current) {
          rectangleRef.current?._toolbars.edit._actionButtons?.[1]?.button.click();
          is_edit_fix.current = true;
        }
      }, 500)
    }, 500)
  }
  const onMountedRect = (e) => {
    rectangleRef.current = e;
    edit_fix();
  };

  useEffect(() => {
    axiosInstance.get('api/fields')
      .then((response) => {
        let data = response.data;
        set_db_data(data);
        put_data(data);
      })
      .catch((error) => {
        console.log(error);
        // throw error
      });
    edit_fix();
  }, []);

  useEffect(() => {
    set_pols_set(() => {
      let p = [];
      for (let i = 0; i < db_data.length; i++) {
        const db = db_data[i];
          let culture = db.cultures?.[active_year]
          let culture_color = '#ffffff'
          if (culture) {
            for (let c = 0; c < culture_list.length; c++) {
              const cc_item = culture_list[c];
              if (culture == cc_item[0]) {
                culture_color = cc_item[1];
              }
            }
          }
        let mp = <MyPol key={db_data[i].id} data_key={db_data[i].id} set_ref={setRef} geometry={db_data[i].geometry} color={is_field?db_data[i].color:culture_color} />
        p.push(mp)
      }
      return p;
    });
  }, [db_data])

  function setRef(e) {
    if (!e[1]?._leaflet_id) return;
    set_fields((mk) => {
      let is_in = false;
      mk.map((m) => { if (m[1]._leaflet_id == e[1]._leaflet_id) is_in = true });
      if (!is_in) {
        for (let i = 0; i < db_data.length; i++) {
          if (db_data[i].id == e[0]) {
            return [...mk, [e[0], e[1], db_data[i].name, db_data[i].manual_area || db_data[i].area, db_data[i].owner]]
          }
        }
      };
      return mk;
    });
  }

  function _onEditPath(e) {
    const {
      layers: { _layers },
    } = e;
    let conf = [];
    set_fields((fl) => {
      let fields_edited = fl;
      for (let i = 0; i < fields_edited.length; i++) {
        Object.values(_layers).map((ll) => {
          if (fields_edited[i][1]._leaflet_id == ll._leaflet_id) {
            let area = calculate_area(ll);
            fields_edited[i] = [fields_edited[i][0], ll, fields_edited[i][2], area, fields_edited[i][4]]
            axiosInstance.patch('api/fields', { id: fields_edited[i][0], geometry: ll.getLatLngs()[0], area: area })
              .then((rp) => {
                let dd = rp.data;
                if (dd.manual_area) {
                  if (dd.area != dd.manual_area) {
                    conf.push(dd);
                  }
                }
              })
              .catch((error) => console.log(error));
          }
        })
      };
      return [...fields_edited];
    });
    setTimeout(() => {
      set_area_conflict(conf);
    }, 500);
  }
  function _onCreate(e) {
    const { layerType, layer } = e;
    const random = Math.floor(Math.random() * colors.length);
    let random_color = colors[random]
    layer.setStyle({ color: random_color });
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      axiosInstance.post('api/fields', { geometry: layer.getLatLngs()[0], area: calculate_area(layer), color: random_color })
        .then((response) => {
          let data = response.data;
          put_data((rs) => [...rs, data]);
          set_fields((mk) => {
            let is_in = false;
            mk.map((m) => { if (m[1]._leaflet_id == _leaflet_id) is_in = true });
            if (!is_in) return [...mk, [data.id, layer, data.name, data.area, data.owner]];
            return mk;
          });
        })
        .catch((error) => console.log(error));
    }
  }

  function _onDeleted(e) {
    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id }) => {
      set_fields((fl) => {
        let f_new = []
        for (let index = 0; index < fl.length; index++) {
          const element = fl[index];
          if (element[1]._leaflet_id == _leaflet_id) {
            axiosInstance.patch('api/fields', { id: element[0], active: false })
              .catch((error) => console.log(error));
          } else {
            f_new.push(element);
          }
        }
        return f_new;
      });
    });
  }


  return (
    <FeatureGroup>
      <EditControl
        onMounted={onMountedRect}
        position='topright'
        onEdited={_onEditPath}
        onCreated={_onCreate}
        onDeleted={_onDeleted}
        onDrawStart={() => block_field_click.current = true}
        onDrawStop={() => setTimeout(() => { block_field_click.current = false }, 1000)}
        onEditStart={() => block_field_click.current = true}
        onEditStop={() => block_field_click.current = false}
        onDeleteStart={() => block_field_click.current = true}
        onDeleteStop={() => block_field_click.current = false}
        draw={{
          polyline: false,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false,
          polygon: true
        }}
      />
      {pols_set}
    </FeatureGroup>
  )
};


function NameInput({ data_id, name, change_name, close_window }) {
  let [new_name, setNew_name] = useState(name);
  return (
    <div className='name_input_cnt'>
      <div className='close' onClick={close_window}></div>
      <div className='box'>
        <form onSubmit={() => { change_name(data_id, new_name) }}>

          <input defaultValue={name} onChange={(e) => setNew_name(e.target.value)} ></input>
          <button type='submit'>OK</button>
        </form>
      </div>
    </div>
  )
}

export default function FieldMap({ onFieldDrawn, logout }) {
  const [fieldCoords, setFieldCoords] = useState([]);
  let arcgis = useRef();
  let osm = useRef();
  const [map_provider_url, setMPU] = useState(arcgis.current);
  const [fields, setFields] = useState([]);
  const [f_markers, setF_markers] = useState([]);
  let x = useRef(localStorage.getItem('x') || 48.78864513988234);
  let y = useRef(localStorage.getItem('y') || 24.72251405390741);
  let zoom_level = useRef(localStorage.getItem('zoom') || 16);
  const [name_input, setName_input] = useState();
  let block_field_click = useRef(false);
  const [db_data, set_db_data] = useState();
  const [f_open, setF_open] = useState(JSON.parse(localStorage.getItem('field_list')) || false);
  let map_ref = useRef();
  const [sum_fields, setSum_fields] = useState(0.0);
  const [map_display_text, setMap_display_text] = useState([true, false, false]);
  const [show_fac, setShow_fac] = useState();
  const area_conflicts = useRef([]);
  const tile_ref = useRef();
  const current_coord = useRef({ lat: 0, lng: 0 });
  const max_coord = useRef({ lat: 0, lng: 0 });
  const step_size = useRef({ lat: 0, lng: 0 });
  const start_point = useRef({ lat: 0, lng: 0 });
  const original_bounds = useRef();
  const tiles_loaded = useRef(false);
  const timer_id = useRef();
  const images = useRef();
  const image_ready = useRef(true);
  const [hide_controls, setHide_controls] = useState(false);
  const list_image_ref = useRef();
  const list_images = useRef([]);
  const list_images_obj = useRef([]);
  const area_sum_ref = useRef();
  const [show_list, setShow_list] = useState(false);
  const area_sum_image = useRef();
  const [generate_status_text, setGenerateText] = useState('');
  const [show_generate, setShow_generate] = useState(false);
  const [gen_status, setGen_status] = useState(false);
  const [gen_return, setGen_return] = useState();
  const image_gen_job_id = useRef();
  const [show_settings, setShow_settings] = useState(false);
  const [active_year, setActive_year] = useState(localStorage.getItem('active_year') || new Date().getFullYear())
  const [year_list, setYear_list] = useState([2024, 2023]);
  const [culture_list, setCulture_list] = useState([]);
  const [to_remove, set_to_remove] = useState(undefined);
  const [is_field, setIsField] = useState(JSON.parse(localStorage.getItem('is_field') || 'true'));



  useEffect(() => {
    localStorage.setItem('active_year', active_year);
  }, [active_year])

  useEffect(() => {
    localStorage.setItem('field_list', JSON.stringify(f_open));
  }, [f_open])

  function update_data(data, data_id) {
    setCulture_list(data['culture_list']);
    setYear_list(data['year_list']);
    if (data['year_list']?.[0]) {
      if (active_year > data['year_list'][0]) {
        setActive_year(data['year_list'][0]);
      }
    } else {
      setActive_year(new Date().getFullYear());
    }
    set_db_data(() => {
      for (let index = 0; index < data['data'].length; index++) {
        const element = data['data'][index];
        if (data_id == element.id) {
          setName_input(element.id);
          break
        }

      }
      return data['data']
    });

  }

  function field_change_color(field_id, field) {

  }

  function change_name(data) {
    setCulture_list(data['culture_list']);
    setYear_list(data['year_list']);
    if (data['year_list']?.[0]) {
      if (active_year > data['year_list'][0]) {
        setActive_year(data['year_list'][0]);
      }
    }
    setFields((fl) => {
      let new_fl = []
      for (let index = 0; index < fl.length; index++) {
        const element = fl[index];
        if (element[0] == data.id) {
          let culture = data.cultures?.[active_year]
          let culture_color = '#ffffff'
          if (culture) {
            for (let c = 0; c < culture_list.length; c++) {
              const cc_item = culture_list[c];
              if (culture == cc_item[0]) {
                culture_color = cc_item[1];
              }
            }
          }
          element[1].setStyle({ color: is_field ? data.color : culture_color })
          new_fl.push([element[0], element[1], data.name, data.manual_area || data.area, data.owner])
        } else {
          new_fl.push(element)
        }
      }
      return new_fl
    });
    setName_input(undefined);
    set_db_data((db) => {
      let new_db = db;
      for (let index = 0; index < db.length; index++) {
        const element = db[index];
        if (element.id == data.id) {
          new_db[index] = data;
        }
      }
      return new_db;
    })

  }

  useEffect(() => {
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      for (let j = 0; j < db_data.length; j++) {
        const db = db_data[j];
        if (db.id == field[0]) {

          let culture = db.cultures?.[active_year]
          let culture_color = '#ffffff'
          if (culture) {
            for (let c = 0; c < culture_list.length; c++) {
              const cc_item = culture_list[c];
              if (culture == cc_item[0]) {
                culture_color = cc_item[1];
              }
            }
          }
          field[1].setStyle({ color: is_field ? db.color : culture_color })
          break
        }
      }
    }
  }, [is_field, culture_list, db_data, active_year])

  function get_db_data(id) {
    for (let index = 0; index < db_data.length; index++) {
      const element = db_data[index];
      if (element.id == id) {
        return element
      }

    }
  }

  function close_form() {
    setName_input(undefined);
  }

  function area_conflict_conf(data) {
    setFields((fl) => {
      let new_fl = []
      for (let index = 0; index < fl.length; index++) {
        const element = fl[index];
        if (element[0] == data.id) {
          element[1].setStyle({ color: data.color })
          new_fl.push([element[0], element[1], data.name, data.manual_area || data.area, data.owner])
        } else {
          new_fl.push(element)
        }
      }
      return new_fl
    });
    setShow_fac(undefined);
    setTimeout(() => {
      let cnf = area_conflicts.current;
      let new_cnf = [];
      for (let index = 0; index < cnf.length; index++) {
        const element = cnf[index];
        if (element.id != data.id) {
          new_cnf.push(element)
        }
      }
      init_area_conflict(new_cnf);
    }, 500)
  }

  function init_area_conflict(data) {
    if (data.length < 1) return;
    let cfl = data[0];
    setShow_fac(() => {
      area_conflicts.current = data;
      return <FieldAreConflict key={cfl.id} data={cfl} set_data={area_conflict_conf} />
    });
  }

  function on_field_click(e) {
    if (block_field_click.current) return;
    if (!e?.target) return;

    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (element[1]._leaflet_id == e.target._leaflet_id) {
        for (let index = 0; index < db_data.length; index++) {
          const db = db_data[index];
          if (db.id == element[0]) {
            setName_input(db.id);
            break;
          }
        }
      }


    }
  }

  useEffect(() => {
    let m = [];
    let sum_f = 0;
    fields.map((fl) => {
      fl[1].on('click', on_field_click);
      if (map_display_text.some((e) => e)) {
        let bb = fl[1].getBounds();
        let bx = bb._northEast.lng - bb._southWest.lng
        let by = bb._northEast.lat - bb._southWest.lat
        let is_vert = parseFloat(by) > parseFloat(bx);
        m.push(<FieldText key={fl[0]} position={fl[1].getCenter()} name={fl[2]} area={fl[3]} owner={fl[4]} mtd={map_display_text} is_vert={is_vert} />);
      }
      sum_f += parseFloat(fl[3]) || 0
    });
    setSum_fields(sum_f.toFixed(2));
    setF_markers(m);
  }, [fields, map_display_text]);

  const handleMapDrawn = () => {
    onFieldDrawn(fieldCoords);  // Pass coordinates to parent component
  };

  function get_fields_bound() {
    if (fields.length < 1) return;
    let init_field = fields[0][1].getBounds()

    let lat_min = init_field._southWest.lat
    let lng_min = init_field._southWest.lng
    let lat_max = init_field._northEast.lat
    let lng_max = init_field._northEast.lng

    for (let index = 0; index < fields.length; index++) {
      const el = fields[index][1].getBounds();
      lat_min = lat_min < el._southWest.lat ? lat_min : el._southWest.lat
      lng_min = lng_min < el._southWest.lng ? lng_min : el._southWest.lng
      lat_max = lat_max > el._northEast.lat ? lat_max : el._northEast.lat
      lng_max = lng_max > el._northEast.lng ? lng_max : el._northEast.lng
    }
    return { _southWest: { lat: lat_min, lng: lng_min }, _northEast: { lat: lat_max, lng: lng_max } }
  }

  function walk() {
    let curr = current_coord.current;
    let max = max_coord.current;
    let point_lat = start_point.current.lat + curr.lat * step_size.current.lat;
    let point_lng = start_point.current.lng + curr.lng * step_size.current.lng;
    tile_ref.current.pan_to({ lat: point_lat, lng: point_lng });
    tiles_loaded.current = false;
    setGenerateText(`Генерую частину карти: ${parseInt(curr.lng) * max.lat + parseInt(curr.lat) + 1} з ${(max.lat * max.lng)}`)
    timer_id.current = setInterval(next_move, 300);

  }

  function wait_on_image() {
    if (!image_ready.current) return;
    if (timer_id.current) {
      clearInterval(timer_id.current);
    }
    let curr = current_coord.current;
    let max = max_coord.current;
    curr.lat += 1;
    if (max.lat <= curr.lat) {
      if (max.lng <= curr.lng + 1) {
        current_coord.current = { lat: 0, lng: 0 }
        tile_ref.current.reset_to_origin(original_bounds.current);
        if (timer_id.current) {
          clearInterval(timer_id.current);
        }
        setShow_list(true);
        setTimeout(generate_list_image, 1000)
        return
      }
      curr.lat = 0;
      curr.lng += 1;
    }
    current_coord.current = curr;
    walk();
  }


  function finish_generation() {
    if (!image_ready.current) return;
    if (timer_id.current) {
      clearInterval(timer_id.current);
    }
    setGenerateText('Генерую фінальну картинку.')
    let data_out = {
      x: max_coord.current.lng,
      y: max_coord.current.lat,
      area_image: area_sum_image.current,
      list: list_images.current,
      job_id: image_gen_job_id.current,
      images: images.current
    }
    var blob = new Blob([JSON.stringify(data_out)], { type: "application/json" });
    var a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "map_image_data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setShow_list(false);
    setHide_controls(false);
    list_images.current = [];
    images.current = [];
    close_gen();
  }

  function next_move() {
    if (!tiles_loaded.current) return;
    tiles_loaded.current = false;
    if (timer_id.current) {
      clearInterval(timer_id.current);
    }
    image_ready.current = false;
    setTimeout(() => get_image(x = current_coord.current.lng, y = current_coord.current.lat), 300);
    timer_id.current = setInterval(wait_on_image, 300);
  }

  function walk_list_child() {
    if (list_images_obj.current.length < 1) {
      if (timer_id.current) {
        clearInterval(timer_id.current);
      }

      domtoimage
        .toJpeg(area_sum_ref.current, { quality: 0.95 })
        .then(function (dataUrl) {
          area_sum_image.current = dataUrl
          image_ready.current = true;

        })
      timer_id.current = setInterval(finish_generation, 200);
      return
    }
    if (!image_ready.current) return;
    image_ready.current = false;
    let el = list_images_obj.current.shift();
    list_image_ref.current.scrollTo({
      top: el.getBoundingClientRect().top - el.getBoundingClientRect().height,
      left: 0,
      behavior: "instant",
    })
    domtoimage
      .toJpeg(el, { quality: 0.95 })
      .then(function (dataUrl) {
        list_images.current.push(dataUrl)
        image_ready.current = true;
      })
  }

  function generate_list_image() {
    if (!list_image_ref.current) return;
    let ch = list_image_ref.current.children;
    list_images.current = [];
    let new_ch = [];
    for (let index = 0; index < ch.length; index++) {
      const element = ch[index];
      new_ch.push(element);
    }
    list_images_obj.current = new_ch;
    setGenerateText('Генерую список полів.')
    if (timer_id.current) {
      clearInterval(timer_id.current);
    }
    timer_id.current = setInterval(walk_list_child, 100);

  }

  function generate_image() {
    images.current = [];
    let fields_bound = get_fields_bound();
    if (!fields_bound) return;
    setGen_status(true);
    setGenerateText('Обчислюю розмір карти полів.')
    setHide_controls(true);
    original_bounds.current = tile_ref.current.get_origin_bounds();
    start_point.current = fields_bound._southWest;
    let init_bound = tile_ref.current.get_start_bounds();

    let step_size_l = { lat: init_bound._northEast.lat - init_bound._southWest.lat, lng: init_bound._northEast.lng - init_bound._southWest.lng }
    let fields_bound_size = { lat: fields_bound._northEast.lat - fields_bound._southWest.lat, lng: fields_bound._northEast.lng - fields_bound._southWest.lng }
    step_size.current = step_size_l;
    max_coord.current = { lat: parseInt((fields_bound_size.lat / step_size_l.lat).toFixed()) + 1, lng: parseInt((fields_bound_size.lng / step_size_l.lng).toFixed()) + 1 };
    tile_ref.current.pan_to(fields_bound._northEast);
    setTimeout(walk(), 500);
  }

  function get_image(x, y) {
    domtoimage
      .toJpeg(document.getElementById('my_map'), { quality: 0.95 })
      .then(function (dataUrl) {
        images.current.push({ x: x, y: y, data: dataUrl })
        image_ready.current = true;
      });
  }

  function field_select(d_id) {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (d_id == element[0]) {
        map_ref.current.fitBounds(element[1].getBounds());
        break;
      }

    }
  }

  useEffect(() => {
    if (osm.current & arcgis.current) return;
    axiosInstance.get('api/app_data').then((response) => {
      let data = response.data;
      osm.current = data['osm'];
      arcgis.current = data['arcgis'];
      setCulture_list(data['culture_list']);
      setYear_list(data['year_list']);
      if (active_year > data['year_list'][0]) {
        if (data['year_list']?.[0]) {
          setActive_year(data['year_list'][0]);
        }
      }
      setMPU(data['arcgis']);
    }).catch((e) => {
      console.log(e)
    })
    let mdt = JSON.parse(localStorage.getItem('mdt')) || [true, false, false]
    setMap_display_text(mdt);
  }, [])
  // zoomControl={false} attributionControl={false}

  function close_gen() {
    setShow_generate(false);
    setGen_status(false);
    setGen_return(undefined);
    image_gen_job_id.current = undefined;
    start_point.current = undefined;
    window.location.href = '/';
    window.location.reload(false);

  }

  function close_settings() {
    setShow_settings(false);
  }

  return (
    <>
      {map_provider_url ?
        <>
          <div id='my_map' className={hide_controls ? 'hide_controls' : ''}>
            <MapContainer ref={map_ref} center={[x.current, y.current]} zoom={zoom_level.current} scrollWheelZoom={true} style={{ height: '100vh' }} >
              <TileLayer eventHandlers={{ loading: () => tiles_loaded.current = false, load: () => tiles_loaded.current = true }} url={map_provider_url} maxZoom={23} maxNativeZoom={18} />
              <MapEvents ref={tile_ref} />
              <Component active_year={active_year} is_field={is_field} culture_list={culture_list} set_fields={setFields} block_field_click={block_field_click} put_data={set_db_data} set_area_conflict={init_area_conflict} />
              {f_markers}
            </MapContainer>
          </div>
          <div className={`map_change_buttons${show_list?' zoom':''}`}>
            <div className={`area_sum${show_list?' zoom':''}`} ref={area_sum_ref}>
              <p className='area_sum_text'>Площа:</p>
              <p>{sum_fields} га</p>
            </div>
            <button className='map_button_save' onClick={() => setShow_generate(true)} title='Завантажити картинку полів'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
            </button>
            <button className='map_button_save' onClick={() => setF_open(!f_open)} style={{ marginTop: '10px' }} title='Список полів'>
              Поля
            </button>
            <button className='map_button_save' onClick={() => setShow_settings(true)} title='Налаштування' style={{ marginTop: '10px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
              </svg>
            </button>
          </div>

          <FieldList is_field={is_field} setIsField={setIsField} culture_list={culture_list} change_id={name_input} list_image_ref={list_image_ref} fields={fields} field_select={field_select} f_open={f_open} set_f_open={setF_open} sum_fields={sum_fields} hide_controls={show_list} active_year={active_year} set_active_year={setActive_year} year_list={year_list} />
          {show_settings ? <SettingsUi set_culture_list={setCulture_list} culture_list={culture_list} logout={logout} set_mdt={setMap_display_text} mdt={map_display_text} close_settings={close_settings} osm={osm.current} arcgis={arcgis.current} set_mpu={setMPU} /> : null}
          {show_fac ? show_fac : null}
        </>
        : null}
      {name_input ? <FieldForm update_data={update_data} data_in={get_db_data(name_input)} active_year={active_year} culture_list={culture_list} set_active_year={setActive_year} year_list={year_list} set_data={change_name} close_form={close_form} /> : null}
      {show_generate ? <GenerateUi close_gen={close_gen} gen_status={gen_status} gen_status_text={generate_status_text} start_gen={generate_image} gen_return={gen_return} /> : null}

    </>
  );
};
