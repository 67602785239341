import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../tools/axiosApi';


function ColorInput({ color, is_checked, on_check }) {
  let my_color = useRef(color);
  return (
    <label className='checkbox_cont_color'>
      <input type='checkbox' onChange={(e) => on_check(e.target.checked, my_color.current)} checked={is_checked} />
      <span className='checkmark_color'></span>
      <div className='color' style={{ backgroundColor: my_color.current }}></div>
    </label >
  )

}

function ColorSelector({ color, set_color }) {
  const colors = ['#FF0000', '#00FFFF', '#0000FF', '#800080', '#FFFF00', '#00FF00', '#FF00FF', '#FFA500', '#7FFFD4'];
  const [color_inputs, setColorInputs] = useState([]);


  function on_color_change(checked, new_color) {
    if (!checked) return;
    set_color(new_color);
  }

  useEffect(() => {
    let ci = [];
    for (let index = 0; index < colors.length; index++) {
      const element = colors[index];
      ci.push(<ColorInput key={index} color={element} is_checked={element == color} on_check={on_color_change} />)
    }
    var half_length = Math.ceil(ci.length / 2);
    setColorInputs([ci.slice(0, half_length), ci.slice(half_length, ci.length)]);
  }, [color])

  return (
    <>
      <div className='color_selector'>
        {color_inputs[0]}
      </div>
      <div className='color_selector'>
        {color_inputs[1]}
      </div>
    </>
  )
}

function DropdownComp({ name, def_value, list, show_input, set_value, set_to_remove, tp }) {
  const [show_dropdown, setShow_dropdown] = useState(false);
  const [list_items, setList_items] = useState([]);

  function list_item_click(e) {
    set_value(e.target.value);
    setShow_dropdown(false);
  }

  function on_remove(e) {
    set_to_remove([tp, e.target.value]);
  }

  useEffect(() => {
    let li = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      li.push(<div key={element} className='dd_item'>
        <button type='button' value={element} onClick={list_item_click} className='dd_value_btn'>{element}</button>
        <button type='button' value={element} className='dd_remove_btn' onClick={on_remove}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
          </svg>
        </button>
      </div>)
    }
    setList_items(li);
  }, [list])

  return (
    <div className="dropdown">
      <div className='year_btn_cont'>
        <button onClick={() => setShow_dropdown((sd) => !sd)} type='button' className="dropbtn list_view_btn"><p>{def_value || '...'}</p>
          {show_dropdown ?
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
            </svg>}
        </button>
        <button onClick={() => show_input(true)} type='button' className="dropbtn list_view_btn_plus">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
          </svg>
        </button>
      </div>
      <div className={`dropdown-content${show_dropdown ? ' show' : ''}`}>
        {list_items}
      </div>
    </div>
  )
}

export default function FieldForm({ data_in, set_data, close_form, active_year, culture_list, year_list, set_active_year, update_data }) {
  const [new_name, setNew_Name] = useState(data_in.name || '');
  const [new_owner, setNew_Owner] = useState(data_in.owner || '');
  const [new_area, setNew_Area] = useState(data_in.manual_area || (data_in.area || 0));
  const [new_color, setNew_color] = useState(data_in.color || '#FFFF00');
  const [cur_culture, setCulture] = useState(data_in.cultures?.[active_year] || '');
  const [cur_active_year, setActive_year] = useState(active_year);
  const [is_loading, set_is_loading] = useState(false);
  const [show_year_input, setShow_year_input] = useState(!(year_list?.length > 0));
  const [show_culture_input, setShow_culture_input] = useState(!(culture_list?.length > 0));
  const [to_remove, setTo_remove] = useState();
  useEffect(() => {
    setCulture(data_in.cultures?.[cur_active_year] || '')
  }, [cur_active_year])

  useEffect(()=>{
    if (show_culture_input) setCulture('');
  }, [show_culture_input])

  function submit(e) {
    e.preventDefault()
    let data = { id: data_in.id }
    data['name'] = new_name;
    data['owner'] = new_owner;
    data['manual_area'] = new_area;
    data['color'] = new_color;
    data['year'] = parseInt(cur_active_year);
    data['culture'] = cur_culture;


    set_is_loading(true);
    axiosInstance.patch('api/fields', data)
      .then((response) => {
        if (cur_culture) set_active_year(cur_active_year);
        set_data(response.data);
        set_is_loading(false);
      }).catch((error) => {
        set_is_loading(false);
      })

  }

  function to_remove_click() {
    let url = 'api/delete/culture';
    if (to_remove[0] == 'y') {
      url = 'api/delete/year'
    }
    axiosInstance.post(url, to_remove[1])
      .then((rp) => {
        if (to_remove[0] == 'c') {
          if (rp.data['culture_list'].length < 1){
            setShow_culture_input(true);
            setShow_year_input(true);
          }
          if (to_remove[1] == cur_culture) {
            setCulture('');
          }
        } else if (to_remove[0] == 'y') {
          if (to_remove[1]==cur_active_year){
            if (rp.data['year_list']?.[0]) {
              setActive_year(rp.data['year_list'][0]);
            } else {
              setActive_year(new Date().getFullYear());
              setShow_year_input(true);
              setCulture('');
            }
          }
        }
          update_data(rp.data, data_in.id);
        })
      .catch((e) => console.log(e));
    setTo_remove(undefined);
  }

  return (
    <div id='login_form'>
      <div className='close' onClick={close_form}></div>
      <div className='login_island'>
        <form onSubmit={submit}>
          <div className='field_input'>
            <p className='noselect'>Назва:</p>
            <input className='email' placeholder='Назва' name='name' type='text' onChange={(e) => setNew_Name(e.target.value)} value={new_name}></input>
          </div>
          <div className='field_input'>
            <p className='noselect'>Власник:</p>
            <input className='email' placeholder='Власник' name='name' type='text' onChange={(e) => setNew_Owner(e.target.value)} value={new_owner}></input>
          </div>
          <div className='field_input'>
            <p className='noselect'>Площа:</p>
            <input className='pass' placeholder='Площа' name='area' type='number' onChange={(e) => setNew_Area(e.target.value)} value={new_area}></input>
          </div>
          <div className='field_input'>
            <p className='noselect'>Культура:</p>
            {!show_culture_input ?
              <DropdownComp def_value={cur_culture} tp={'c'} set_value={setCulture} show_input={setShow_culture_input} set_to_remove={setTo_remove} list={culture_list.map((cl)=>cl[0])} /> :
              <input placeholder='Культура' type='text' onChange={(e) => setCulture(e.target.value)} value={cur_culture}></input>
            }
          </div>
          <div className='field_input'>
            <p className='noselect'>Рік:</p>
            {!show_year_input ?
              <DropdownComp def_value={cur_active_year} tp={'y'} set_value={setActive_year} show_input={setShow_year_input} list={year_list} set_to_remove={setTo_remove} /> :
              <input placeholder='Рік' type='number' onChange={(e) => setActive_year(e.target.value)} value={cur_active_year}></input>
            }
          </div>
          {<ColorSelector color={new_color} set_color={setNew_color} />}
          <div className='field_input_buttons'>
            <button type='button' onClick={close_form}>СКАСУВАТИ</button>
            <button className='submit' type='submit'>ЗБЕРЕГТИ</button>
          </div>
        </form>
        {is_loading ?
          <div className='login_error' />
          : null}
        {to_remove ?
          <div className='remove_confirm'>
            <div className='rm_cont'>
              <p>Ви точно хочете видалити {to_remove[1]}?</p>
              <div className='btns'>
                <button onClick={() => setTo_remove(undefined)}>НІ</button>
                <button className='red' onClick={to_remove_click}>ТАК</button>
              </div>
              <p className='red'>Усі записи повзязані з цим будуть видалені!</p>
              <p className='red'>(поля залишаться)</p>
            </div>
          </div>
          : null}
      </div>
    </div>
  )
}