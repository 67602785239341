
import { useEffect, useState, useRef } from 'react';
import satellite_map_image from '../images/satellite.jpg';
import schematic_map_image from '../images/schematic.jpg';
import axiosInstance from '../tools/axiosApi';

function ColorSelector({ culture, set_color }) {
    const colors = ['#FF0000', '#00FFFF', '#0000FF', '#800080', '#FFFF00', '#00FF00', '#FF00FF', '#FFA500', '#7FFFD4'];
    const [color_inputs, setColorInputs] = useState([]);

    useEffect(()=>{
        let ci = [];
        for (let index = 0; index < colors.length; index++) {
            const element = colors[index];
            ci.push(<button key={index} className='color_input_btn' value={element} style={{backgroundColor: element}} onClick={(e)=>set_color(culture, e.target.value)}></button>)
        }
        setColorInputs(ci);
    }, [])

    return (
        <div className='color_inputs_cont'>

        <div className='color_inputs'>
            <p>{culture}</p>
            {color_inputs}
        </div>
        </div>
    )
  }

function Culture_input_color({ culture, def_color, change_color, on_color_change }) {
    const [color_value, setColor_calor] = useState(def_color);

    function set_color(e) {
        change_color(<ColorSelector culture={culture} set_color={on_color_change}/>)
    }

    return (
        <div className='culture_color'>
            <p>{culture}:</p>
            <button className='color_input_btn' value={def_color} style={{backgroundColor: def_color}} onClick={set_color}></button>
        </div>
    )
}

export default function SettingsUi({ set_mdt, mdt, logout, close_settings, osm, arcgis, set_mpu, culture_list, set_culture_list }) {

    const [culture_items, set_culture_items] = useState();
    const [color_selector, set_color_selector] = useState();
 
    function handle_check(id, value) {
        set_mdt((mtd_l) => {
            let mdt_new = mtd_l;
            mdt_new[id] = value;
            localStorage.setItem('mdt', JSON.stringify(mdt_new));
            return [...mdt_new];
        })
    }

    function on_color_change(culture, color) {
        axiosInstance.post('api/update/culture', [culture, color])
        .then((rp)=>{
            set_culture_list(rp.data['culture_list']);
        })
        .catch((e)=>{
            console.log(e);
        })
        set_color_selector(undefined);
    }

    useEffect(() => {
        let ci = [];
        for (let index = 0; index < culture_list.length; index++) {
            const element = culture_list[index];
            ci.push(<Culture_input_color key={index} culture={element[0]} def_color={element[1]} change_color={set_color_selector} on_color_change={on_color_change} />)
        }
        set_culture_items(ci);
    }, [culture_list])

    return (
        <div className="settings_panel">
            <div className='close' onClick={close_settings} ></div>
            <div className='settings_island'>
                <div className='settings_item'>
                    <div className='settings_head'>
                        <p>Вигляд карти</p>
                    </div>
                    <div className='settings_control map_image'>
                        <button className='map_button'>
                            <img src={satellite_map_image} onClick={() => set_mpu(arcgis)} title='Супутник'></img>
                            <p>Супутник</p>
                        </button>
                        <button className='map_button'>
                            <img src={schematic_map_image} onClick={() => set_mpu(osm)} title='Супутник'></img>
                            <p>Схема</p>
                        </button>
                    </div>
                </div>
                <div className='settings_item'>
                    <div className='settings_head'>
                        <p>Показувати на карті</p>
                    </div>
                    <div className='settings_control'>
                        <label className='checkbox_cont'>Номер
                            <input type='checkbox' onChange={(e) => handle_check(0, e.target.checked)} checked={mdt[0]} />
                            <span className='checkmark'></span>
                        </label >
                        <label className='checkbox_cont'>Площу
                            <input type='checkbox' onChange={(e) => handle_check(1, e.target.checked)} checked={mdt[1]} />
                            <span className='checkmark'></span>
                        </label >
                        <label className='checkbox_cont'>Власника
                            <input type='checkbox' onChange={(e) => handle_check(2, e.target.checked)} checked={mdt[2]} />
                            <span className='checkmark'></span>
                        </label >
                    </div>
                </div>
                {culture_list.length > 0 ?
                    <div className='settings_item'>
                        <div className='settings_head'>
                            <p>Колір культур</p>
                        </div>
                        <div className='settings_control'>
                            {culture_items}
                        </div>
                    </div>
                    : null}
                <div className='settings_item'>
                    <div className='settings_head'>
                        <p>Вийти з акаунту</p>
                    </div>
                    <div className='settings_control'>
                        <div className="exit_button">
                            <button className="red_button" onClick={() => logout(true)}>
                                ВИЙТИ
                            </button>
                        </div>
                    </div>
                </div>
                <button onClick={close_settings} className='settings_cancel'>ЗАКРИТИ</button>
                {color_selector}
            </div>
        </div >
    )
}