import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../tools/axiosApi';


export default function LoginForm({close_login}){
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [errors, setErrors] = useState('');
    const [pass_errors, setPass_Errors] = useState('');
    const [login_error, setLogin_Error] = useState(false);
    const [is_loading, set_is_loading] = useState(false);

    let timer_id = useRef();

    const validate = (values, text) => {
        if (!values) {
          setErrors(`Введіть Email!`)
          return false
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
        //   setErrors('Поганий Email!')
        //   return false
        }
        setErrors('');
        return true
      }
    
      function submit(e){
        e.preventDefault()
        let ret = false;
        if (!validate(email)) ret=true;
        if (!pass){
            setPass_Errors('Введіть пароль!');
            ret = true;
        }
        if (ret) return;
        set_is_loading(true);
        axiosInstance.post('auth/token/obtain/', {
            username: email,
            password: pass
        }).then((response)=>{
            set_is_loading(false);
            let data = response.data
            axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access;
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);
            setPass('');
            window.location.href = '/';
            window.location.reload(false);
        }).catch((error)=>{
            set_is_loading(false);
            setLogin_Error(true);
            setPass('');
            if (timer_id.current) clearTimeout(timer_id.current);
            timer_id.current = setTimeout(()=>{
                setLogin_Error(false);
            }, 2000);
        })

      }

    return(
        <div id='login_form'>
            <div className='login_island login_f'>
                <form onSubmit={submit} className='login_f'>
                    <input className='email' onFocus={(e)=>setErrors('')} placeholder='Логін' name='login' type='text' onChange={(e)=>setEmail(e.target.value)}></input>
                    {errors?<p className='error'>{errors}</p>:null}
                    <input className='pass' onFocus={(e)=>setPass_Errors('')} placeholder='Пароль' name='password' type='password' onChange={(e)=>setPass(e.target.value)} value={pass}></input>
                    {pass_errors?<p className='error'>{pass_errors}</p>:null}
                    <button type='submit'>УВІЙТИ</button>
                </form>
                {login_error?
                <div className='login_error'>
                    <div>
                        <p>Не вдалося увійти!</p>
                    </div>
                </div>
                :null}
                {login_error?
                <div className='login_error'>
                    <div>
                        <p className='noselect'>Не вдалося увійти!</p>
                    </div>
                </div>
                :null}
                {is_loading?
                <div className='login_error'/>
                :null}
            </div>
        </div>
    )
}