import axios from 'axios'

const bkend_host_prod = '/';
const bkend_host_dev = 'http://localhost:8000/';

const URL = process.env.NODE_ENV === 'development' ? bkend_host_dev : bkend_host_prod;
const event = new Event('onLoginRequest');

const axiosInstance = axios.create({
    baseURL: URL,
    timeout: 5000,
    headers: {
        'Authorization': "JWT " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        // Prevent infinite loops
        if (error.response.status === 401 && originalRequest.url === URL+'auth/token/refresh/') {
            // window.location.href = '/login/';
            document.dispatchEvent(event);
            return Promise.reject(error);
        }

        if (error.response.data.code === "token_not_valid" &&
            error.response.status === 401 && 
            error.response.statusText === "Unauthorized") 
            {
                const refreshToken = localStorage.getItem('refresh_token');

                if (refreshToken){
                    const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                    // exp date in token is expressed in seconds, while now() returns milliseconds:
                    const now = Math.ceil(Date.now() / 1000);
                    console.log(tokenParts.exp);

                    if (tokenParts.exp > now) {
                        return axiosInstance
                        .post('auth/token/refresh/', {refresh: refreshToken})
                        .then((response) => {
            
                            localStorage.setItem('access_token', response.data.access);
                            localStorage.setItem('refresh_token', response.data.refresh);
            
                            axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                            originalRequest.headers['Authorization'] = "JWT " + response.data.access;
            
                            return axiosInstance(originalRequest);
                        })
                        .catch(err => {
                            console.log(err)
                        });
                    }else{
                        console.log("Refresh token is expired", tokenParts.exp, now);
                        // window.location.href = '/login/';
                        document.dispatchEvent(event);
                    }
                }else{
                    console.log("Refresh token not available.")
                    // window.location.href = '/login/';
                    document.dispatchEvent(event);
                }
        }
      
      // specific error handling done elsewhere
      return Promise.reject(error);
  }
);



export default axiosInstance;