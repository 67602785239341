import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../tools/axiosApi';

function gen_text() {
  return (<>
  </>
  )
}

export default function GenerateUi({ start_gen, gen_status_text, close_gen, gen_return, gen_status }) {
  const [is_loading, set_is_loading] = useState(false);
  const a_ref = useRef();


  // function submit(e) {
  //   e.preventDefault()
  //   set_is_loading(true);
  //   axiosInstance.patch('api/fields', {'manual_area':null, 'id': data.id})
  //     .then((response) => {
  //       set_data(response.data);
  //       set_is_loading(false);
  //     }).catch((error) => {
  //       set_is_loading(false);
  //     })
  // }

  function cancel() {
    close_gen();
    window.location.href = '/';
    window.location.reload(false);
  }

  function done(){
    a_ref.current.click();
    setTimeout(close_gen, 500);
  }

  return (
    <div id='login_form'>
      <div className='login_island'>
        <div className='generate_ui'>
          {gen_status ?
            <>
              {gen_return ?
                <>
                  <h4>Генерація завершена!</h4>
                  <p>Занавнтажте картинку, натиснувши кнопку.</p>
                  <a href={gen_return} ref={a_ref} download></a>
                  <div className='generate_ui_buttons download'>
                    <button className='download_b' onClick={done}>ЗАВАНТАЖИТИ</button>
                    <button onClick={cancel}>СКАСУВАТИ</button>
                  </div>
                </> :
                <>
                  <h4>Генерую картинку, будь ласка НЕ перезавантажуйте сторінку.</h4>
                  <p>{gen_status_text}</p>
                  <div className='generate_ui_buttons'>
                    <button onClick={cancel}>СКАСУВАТИ</button>
                  </div>
                </>}</>
            : <>
              <h4>Створити файл з данними усіх полів?</h4>
              <p>Це процес може зайняти від 1хв до 15хв.</p>
              <p> Усе залежить від швидкості Вашого інтернету, розміру екрану та самого пристрою.</p>
              <p style={{marginTop:10}}>Щоб перетворити отриманий файл в картинку, використайте програму на компютері "ГенераторКарти.exe"</p>
              <p><a href='/media/ГенераторКарти.exe' download>Завантажити програму</a></p>
              <div className='generate_ui_buttons'>
                <button onClick={cancel}>СКАСУВАТИ</button>
                <button onClick={start_gen}>ГЕНЕРУВАТИ</button>
              </div>
            </>}
        </div>
        {is_loading ?
          <div className='login_error' />
          : null}
      </div>
    </div>
  )
}